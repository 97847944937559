:root {
    --sidebar-text-color: rgba(255, 255, 255, 0.75);

    --sidebar-font-root: 0.8rem;
    --sidebar-font-child: 0.7rem;
    --sidebar-treegrid-expander-font-size: 0.7rem;
    --sidebar-treegrid-line-spacing: 1;

    --sidebar-width: 250px;

    /* Bootstrap colors */
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a74t;
    --teal: #20c997;
    --cyan: #17a2b8;
    --gray-dark: #343a40;
    --gray: #6c757d
}