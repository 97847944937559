* {
    box-sizing: border-box;
}

.h2 {
    font-size: 26px;
    color: #25718c;
    font-weight: 400;
    margin-bottom: 32px;
}

.preloader-box {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 0;
}
.preloader {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.preloader-box.show {
    display: flex;
    opacity: 1 !important;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.login {
    height: 100vh;
    background-color: #074e6f;
}

.login > div.d-flex {
    -webkit-transform: translateY(-10%);
    transform: translateY(-10%);
    z-index: 1;
}

.login-logo {
    background: url(../images/logo/logo_hub.png) no-repeat;
    background-size: contain;
    width: 70px;
    height: 70px;
    margin-bottom: 60px;
}

.login-form {
    background: #dadada;
    border-radius: 5px;
    width: 400px;
    padding: 3rem;
}

.btn-custom {
    background: #25718c;
    border-color: #25718c;
    color: #fff;
}

.btn-custom.active {
    background: #195769 !important;
    border-color: #195769 !important;
}

.btn-custom:hover {
    background: #2d82a3;
    border-color: #2d82a3;
}

.login-lines {
    background: url(../images/backgroung/login_lines.png);
    background-size: auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 484px;
    display: block;
}

.login-left {
    background: url(../images/backgroung/login_drillLeft.png);
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 808px;
    width: 600px;
    max-height: 940px;
    height: 600px;
    display: block;
}

.login-right {
    background: url(../images/backgroung/login_drillRight.png);
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 560px;
    max-height: 650px;
    height: 400px;
    width: 400px;
    display: block;
}

.brand-icon {
    background: url(../images/logo/logo_hub.png) no-repeat;
    background-position-x: left;
    height: 52px;
    width: 150px;
    min-width: 150px;
    background-size: contain;
}

.bg-navbar {
    background-color: #03405b;
}

.wrapper {
    background-color: #0a2333;
    width: 100%;
    min-height: 100vh;
}

.heading-super {
    background: url(../images/account/super.png) center no-repeat;
    display: inline-block;
    height: 50px;
    width: 50px;
    min-width: 50px;
    background-size: cover;
    border-radius: 50%;
}

.heading-info {
    width: 10.5rem;
    display: inline-block;
    padding-left: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
}

.heading-info a {
    color: #b2b2b2;
}

.heading-employee {
    font-size: 0.9rem;
    font-weight: bold;
    width: 100%;
}

.heading-employee.position {
    font-size: 0.8rem;
    font-style: italic;
    font-weight: 100;
}

.btn-collapse[aria-expanded="true"] i.fa:before {
    content: "\f077";
}

.btn-collapse[aria-expanded="false"] i.fa:before {
    content: "\f078";
}

.btn-block-collapse hr {
    background: #25718c;
}

.btn-collapse {
    margin-top: -60px;
    padding: 2px 8px;
    border-radius: 10px;
    line-height: 1;
    font-size: 12px;
}

/*.modal-content {*/
/*    background-color: #003a51;*/
/*}*/

/*.close, .close:hover {*/
/*    color: #fff;*/
/*}*/

.container-grid {
    display: grid;
    display: -ms-grid;
    grid-template-rows: 40px auto 1fr;
    -ms-grid-rows: 40px auto 1fr;
    grid-template-columns: 100%;
    -ms-grid-columns: 100%;
    height: 100%;
}

.container-grid > *:nth-child(1) {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
}

.container-grid > *:nth-child(2) {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
}

.container-grid > *:nth-child(3) {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
}

.link {
    border: 1px solid #03405b;
    border-radius: 5px;
    transition: .3s ease;
}
.link:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    text-decoration: none;
}
.link .image {
    text-align: center;
}
.name {
    background-color: #03405b;
    color: #fff;
}
.indent_all {
    color: #50bade;
    position: relative;
}

.head-table {
    background-color: #001d30;
}

.head-table > div, .body-table > div {
    flex: 0 0 250px;
    padding: 0.75rem;
    color: #fff;
    font-size: 0.76rem;
    font-weight: 700;
}
.body-table > div {
    font-weight: 400;
    color: #ffb31a;
}
.head-table > div:first-child, .body-table > div:first-child {
    flex: initial;
    width: 100%;
}
.head-table > div:last-child, .body-table > div:last-child {
    flex: 0 0 100px;
    text-align: right;
}
.node > .node {
    padding-left: 16px;
    display: none;
}
.node.open > .node {
    display: block;
}
.node > div > div > .fa:before {
    content: "\f054";
}
.node.open > div > div > .fa:before {
    content: "\f078";
}
.node {
    background-color: #171714;
    line-height: 1;
}
.node .toggle {
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.modal-dialog-scrollable .modal-body {
    overflow-x: hidden;
}